import { t } from "@lingui/macro";
import { Close } from "@mui/icons-material";
import { Instagram, LinkedIn, Telegram, YouTube } from "@mui/icons-material";
import HeadsetMicRoundedIcon from "@mui/icons-material/HeadsetMicRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LibraryBooksRoundedIcon from "@mui/icons-material/LibraryBooksRounded";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import WorkIcon from "@mui/icons-material/Work";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";

import { useUser } from "@/client/services/user";

import { DropdownButton } from "./dropdown-button";
import { SectionButton } from "./section-button";

export const INSTAGRAM_LINK = "https://www.instagram.com/networkio/";
export const LINKEDIN_LINK = "https://www.linkedin.com/company/networkioio";
export const YOUTUBE_LINK = "https://www.youtube.com/@networkioio";
export const TELEGRAM_LINK = "https://t.me/networkio_io";

export const NetworkioMenu = () => {
  const user = useUser();
  const isTablet = useMediaQuery("(max-width:1124px)");
  const [isMobileMenuOpen, setisMobileMenuOpen] = useState(false);

  const toggleMenu = () => {
    setisMobileMenuOpen((prev) => !prev);
  };

  const commonNav = () => (
    <>
      <SectionButton title={t`Jobs`} href={"https://networkio.io/jobs"} isMobile={isTablet} />

      <SectionButton
        title={t`Articles`}
        href={"https://networkio.io/articles"}
        isMobile={isTablet}
      />

      <DropdownButton
        title={t`AITools`}
        sections={[
          {
            href: "https://resume.networkio.io/",
            title: t`Resume Builder Tool`,
          },
          {
            href: "https://networkio.io/tools/cover-letter",
            title: t`Cover Letter Tool`,
          },
          {
            href: "https://networkio.io/tools/interview-coach",
            title: t`Interview Coach Tool`,
          },
          {
            href: "https://networkio.io/tools/career-summary",
            title: t`Career Summary Tool`,
          },
          {
            href: "https://networkio.io/tools/job-keywords",
            title: t`Job Keywords Tool`,
          },
          {
            href: "https://networkio.io/tools/achievements",
            title: t`Achievements Generator Tool`,
          },
        ]}
        isMobile={isTablet}
      />

      <DropdownButton
        title={t`Coaching`}
        sections={[
          {
            href: "https://networkio.io/services",
            title: t`Corporate career`,
          },
          {
            href: "https://www.networkio.io/ru/services/un",
            title: t`Career in the UN`,
          },
          {
            href: "https://www.networkio.io/ru/services/interviews",
            title: t`Interview services`,
          },
        ]}
        isMobile={isTablet}
      />

      <DropdownButton
        title={t`About us`}
        sections={[
          {
            href: "https://networkio.io/about",
            title: t`Who we are`,
          },
          {
            href: "https://networkio.io/international-career",
            title: t`International career`,
          },
          {
            href: "https://networkio.io/career-in-the-united-nations",
            title: t`Career in the UN`,
          },
          {
            href: "https://networkio.io/internships",
            title: t`Internships`,
          },
          {
            href: "https://networkio.io/remote-work",
            title: t`Remote work`,
          },
          {
            href: "https://networkio.io/partnership",
            title: t`Partnership`,
          },
          {
            href: "https://networkio.io/affiliates",
            title: t`Promotion`,
          },
        ]}
        isMobile={isTablet}
      />
    </>
  );

  if (isTablet) {
    return (
      <>
        <Box width="100%" position="fixed" left="0" bottom="0" zIndex="500">
          <Navigation showLabels>
            <NavigationAction
              value=""
              label={t`Home`}
              icon={<HomeRoundedIcon />}
              onClick={() => (window.location.href = "https://networkio.io/")}
            />
            <NavigationAction
              value="jobs"
              label={t`Jobs`}
              icon={<WorkIcon />}
              onClick={() => (window.location.href = "https://networkio.io/jobs")}
            />
            <NavigationAction
              value="coaching"
              label={t`Coaching`}
              icon={<HeadsetMicRoundedIcon />}
              onClick={() => (window.location.href = "https://networkio.io/services")}
            />
            <NavigationAction
              value="articles"
              label={t`Articles`}
              icon={<LibraryBooksRoundedIcon />}
              onClick={() => (window.location.href = "https://networkio.io/articles")}
            />
            <NavigationAction label={t`More`} icon={<MoreHorizIcon />} onClick={toggleMenu} />
          </Navigation>
        </Box>

        <Dialog
          fullScreen
          open={isMobileMenuOpen}
          onClose={() => {
            setisMobileMenuOpen(false);
          }}
        >
          <IconButton
            style={{ position: "absolute", right: "5px", top: "5px" }}
            size="large"
            onClick={() => {
              setisMobileMenuOpen(false);
            }}
          >
            <Close htmlColor="#000" fontSize="large" />
          </IconButton>
          <Box
            alignItems={"center"}
            // eslint-disable-next-line lingui/no-unlocalized-strings
            margin={`20px 0 0 40px`}
            display="flex"
          >
            <a href={TELEGRAM_LINK} target="_blank" rel="noreferrer">
              <Telegram
                style={{
                  color: "#6A6A6A",
                  width: 25,
                  height: 25,
                }}
                fontSize="large"
              />
            </a>

            <Box width={20} />

            <a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer">
              <Instagram
                style={{
                  color: "#6A6A6A",
                  width: 25,
                  height: 25,
                }}
                fontSize="large"
              />
            </a>

            <a href={LINKEDIN_LINK} target="_blank" rel="noreferrer">
              <LinkedIn
                style={{
                  color: "#6A6A6A",
                  width: 25,
                  height: 25,
                }}
                fontSize="large"
              />
            </a>

            <a href={YOUTUBE_LINK} target="_blank" rel="noreferrer">
              <YouTube
                style={{
                  color: "#6A6A6A",
                  width: 25,
                  height: 25,
                }}
                fontSize="large"
              />
            </a>
          </Box>
          <DialogContent>
            <ul className="nav__mobile">{commonNav()}</ul>
          </DialogContent>
          <Box height="40px" />
        </Dialog>
      </>
    );
  }

  return (
    <ul
      style={{ zIndex: 100, background: "#fafafa" }}
      className={"nav-nwk nav-nwk-left items-center"}
    >
      {!user.user?.isFellow && (
        <Button
          component={Link}
          variant="contained"
          href={"https://networkio.io/subscribe"}
          sx={{
            paddingTop: "8px",
            fontSize: "13px",
            borderRadius: "20px",
          }}
        >
          {t`Join the club`}
        </Button>
      )}

      {commonNav()}
    </ul>
  );
};

const NavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
  "&.MuiButtonBase-root.MuiBottomNavigationAction-root.Mui-selected": {
    color: theme.palette.primary.main,
  },
  "&.MuiButtonBase-root": {
    color: theme.palette.text.secondary,
    minWidth: "70px",
  },
  "&.Mui-selected": {
    color: theme.palette.primary.main,
  },
}));

const Navigation = styled(BottomNavigation)(({ theme }) => ({
  // eslint-disable-next-line lingui/no-unlocalized-strings
  border: "1px solid #f1f1f1",
  // eslint-disable-next-line lingui/no-unlocalized-strings
  padding: "0 20px",
}));
