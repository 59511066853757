/* eslint-disable @typescript-eslint/no-explicit-any */
import { t, Trans } from "@lingui/macro";
import { Button, Tooltip } from "@networkio-resume/ui";
import { Question } from "@phosphor-icons/react";
import { useMemo } from "react";

import { useAuthStore } from "../stores/auth";

export const OpenAiTokens = () => {
  const { user } = useAuthStore();

  const tokens = useMemo(() => {
    const isFellow = user?.isFellow;

    const maxTokens = isFellow ? 500 : 5;

    return {
      max: maxTokens,
      curr: user?.chatGptTokensLeft ?? 0,
    };
  }, [user]);

  const clubLink =
    user?.locale === "ru"
      ? "https://www.networkio.io/ru/subscribe"
      : "https://www.networkio.io/en/subscribe";

  return (
    <div className="inline-flex items-center justify-center rounded-full bg-background px-4 shadow-xl">
      <Tooltip
        content={
          (
            <Trans>
              You can now edit your resume with AI! If you need more tokens check out our{" "}
              <a href={clubLink} className="underline" target="_blank" rel="noopener noreferrer">
                club membership
              </a>
              .
            </Trans>
          ) as any
        }
      >
        <Button variant="ghost" className="rounded-none hover:bg-inherit cursor-default">
          {`${tokens.curr}\\${tokens.max} ${t`AI tokens`}`}
          <Question />
        </Button>
      </Tooltip>
    </div>
  );
};
