"use client";

import { useEffect, useState } from "react";

export const usePageRetainSignal = (retainTimeSeconds = 120) => {
  const [isRetainSignal, setIsRetainSignal] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    // Start the timer when the component mounts
    timer = setTimeout(() => {
      setIsRetainSignal(true);
    }, retainTimeSeconds * 1000);

    // Cleanup the timer when the component unmounts
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [retainTimeSeconds]);

  return isRetainSignal;
};
