import { ArrowDropDown } from "@mui/icons-material";
import { Box, ClickAwayListener, Link, MenuItem, Popper, Slide } from "@mui/material";
import React from "react";

type DropdownButtonProps = {
  title: string;
  sections: {
    href: string;
    title: string;
  }[];
  isMobile?: boolean;
};

export const DropdownButton: React.FC<DropdownButtonProps> = ({ sections, title, isMobile }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    if (open) {
      handleClose();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  if (isMobile) {
    return (
      <Box>
        <li>
          <p className={"link__mobile_title"} style={{ margin: 0 }}>
            {title}
          </p>
        </li>
        <Box paddingLeft={"10px"}>
          {sections.map((s, index) => (
            <li key={index} onClick={(e) => (window.location.href = s.href)}>
              <Link className={"link__mobile_title"} underline="hover">
                {`- ${s.title}`}
              </Link>
            </li>
          ))}
        </Box>
      </Box>
    );
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box position={"relative"}>
        <li style={{ cursor: "pointer" }} onClick={handleClick}>
          <a className={"link__title"}>
            {title}

            <ArrowDropDown
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                margin: "auto",
                right: "-15px",
              }}
            />
          </a>
        </li>
        <Popper
          transition
          open={open}
          style={{ zIndex: 10 }}
          anchorEl={anchorEl}
          placement={"bottom-start"}
        >
          {({ TransitionProps }) => (
            <Slide {...TransitionProps} timeout={350}>
              <Box
                sx={{
                  padding: "10px",
                  backgroundColor: "#fafafa",
                  color: "text.primary",
                  // eslint-disable-next-line lingui/no-unlocalized-strings
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                  // eslint-disable-next-line lingui/no-unlocalized-strings
                  borderRadius: "0 0 10px 10px",
                }}
              >
                {sections.map((s, index) => (
                  <MenuItem
                    key={index}
                    style={{ padding: "10px" }}
                    onClick={() => (window.location.href = s.href)}
                  >
                    {s.title}
                  </MenuItem>
                ))}
              </Box>
            </Slide>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};
