import { t } from "@lingui/macro";
import { Dialog, DialogContent } from "@mui/material";
import { Badge, Button, DropdownMenuContent, DropdownMenuItem } from "@networkio-resume/ui";
import { cn } from "@networkio-resume/utils";
import {
  CaretDown,
  ChatTeardropText,
  CircleNotch,
  Exam,
  MagicWand,
  PenNib,
} from "@phosphor-icons/react";
import { DropdownMenu, DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { useState } from "react";

import { changeTone } from "../services/openai/change-tone";
import { fixGrammar } from "../services/openai/fix-grammar";
import { improveWriting } from "../services/openai/improve-writing";
import { updateUser, useUser } from "../services/user";
import { useAuthStore } from "../stores/auth";

type Action = "improve" | "fix" | "tone";
type Mood = "casual" | "professional" | "confident" | "friendly";

type Props = {
  value: string;
  onChange: (value: string) => void;
  className?: string;
};

export const AiActions = ({ value, onChange, className }: Props) => {
  const [loading, setLoading] = useState<Action | false>(false);
  const [noMoreTokensError, setNoMoreTokensError] = useState(false);
  const { user } = useUser();

  const onClick = async (action: Action, mood?: Mood) => {
    try {
      setLoading(action);

      let textResult = value;
      let chatGptTokensLeft = user?.chatGptTokensLeft;
      if (action === "improve") {
        const writingRes = await improveWriting(value, user?.nwAccessToken ?? "");
        textResult = writingRes.result;
        chatGptTokensLeft = writingRes.tokensLeft;
      }

      if (action === "fix") {
        const grammarRes = await fixGrammar(value, user?.nwAccessToken ?? "");
        textResult = grammarRes.result;
        chatGptTokensLeft = grammarRes.tokensLeft;
      }

      if (action === "tone" && mood) {
        const moodRes = await changeTone(value, mood, user?.nwAccessToken ?? "");
        textResult = moodRes.result;
        chatGptTokensLeft = moodRes.tokensLeft;
      }

      const updUser = await updateUser({ chatGptTokensLeft });

      useAuthStore.setState({ user: updUser });

      onChange(textResult);
    } catch (error) {
      setNoMoreTokensError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className={cn(
          "relative mt-4 rounded bg-secondary-accent/50 p-3 outline outline-secondary-accent",
          "flex flex-wrap items-center justify-center gap-2",
          className,
        )}
      >
        <div className="absolute -left-5 z-10">
          <Badge
            outline
            variant="primary"
            className="-rotate-90 bg-background px-2 text-[10px] leading-[10px]"
          >
            <MagicWand size={10} className="mr-1" />
            {t`AI`}
          </Badge>
        </div>

        <Button size="sm" variant="outline" disabled={!!loading} onClick={() => onClick("improve")}>
          {loading === "improve" ? <CircleNotch className="animate-spin" /> : <PenNib />}
          <span className="ml-2 text-xs">{t`Improve Writing`}</span>
        </Button>

        <Button size="sm" variant="outline" disabled={!!loading} onClick={() => onClick("fix")}>
          {loading === "fix" ? <CircleNotch className="animate-spin" /> : <Exam />}
          <span className="ml-2 text-xs">{t`Fix Spelling & Grammar`}</span>
        </Button>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size="sm" variant="outline" disabled={!!loading}>
              {loading === "tone" ? <CircleNotch className="animate-spin" /> : <ChatTeardropText />}
              <span className="mx-2 text-xs">{t`Change Tone`}</span>
              <CaretDown />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => onClick("tone", "casual")}>
              <span role="img" aria-label={t`Casual`}>
                🙂
              </span>
              <span className="ml-2">{t`Casual`}</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => onClick("tone", "professional")}>
              <span role="img" aria-label={t`Professional`}>
                💼
              </span>
              <span className="ml-2">{t`Professional`}</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => onClick("tone", "confident")}>
              <span role="img" aria-label={t`Confident`}>
                😎
              </span>
              <span className="ml-2">{t`Confident`}</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => onClick("tone", "friendly")}>
              <span role="img" aria-label={t`Friendly`}>
                😊
              </span>
              <span className="ml-2">{t`Friendly`}</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <Dialog
        open={noMoreTokensError}
        sx={{
          "& .MuiDialogContent-root": {
            padding: 0,
          },
        }}
        onClose={() => {
          setNoMoreTokensError(false);
        }}
      >
        <DialogContent>
          <a
            onClick={() => {
              setNoMoreTokensError(false);
            }}
            href="https://www.networkio.io/ru/club"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/networkio/ai-tokens.png" />
          </a>
        </DialogContent>
      </Dialog>
    </>
  );
};
