import { Dialog, DialogContent } from "@mui/material";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const PromptBanner = ({ open, onClose }: Props) => {
  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialogContent-root": {
          padding: 0,
        },
      }}
      onClose={onClose}
    >
      <DialogContent>
        <a
          onClick={() => {
            onClose();
          }}
          href="https://www.networkio.io/ru/services"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/networkio/resume-writing.png" />
        </a>
      </DialogContent>
    </Dialog>
  );
};
