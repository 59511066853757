import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#E7A058",
      dark: "#dc934e",
      light: "#eeb37e",
      contrastText: "#fff",
    },
    info: {
      main: "#fff",
      light: "#eff0fa",
      dark: "#5b5b88",
      contrastText: "#6979F8",
    },
    secondary: {
      main: "#AEADEB",
      dark: "#8988d5",
      light: "#d1d1fc",
      contrastText: "#fff",
    },
    success: {
      main: "#fff",
      light: "#f6ebe3",
      dark: "#5b5b88",
      contrastText: "#6979F8",
    },
    background: {
      default: "#eff0fa",
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
    text: {
      primary: "#202020",
      secondary: "#646464",
      disabled: "#ACAEAE",
    },
  },
  typography: {
    fontFamily: "inherit",
  },
  mixins: {
    toolbar: {
      minHeight: 63,
      padding: 0,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.MuiTypography-root": {
            letterSpacing: "normal",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        underlineHover: {
          "&:hover": {
            textDecoration: "none",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "#dc934e",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            // eslint-disable-next-line lingui/no-unlocalized-strings
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: 1,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#dc934e",
            borderWidth: 1,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          background: "#fff",
          overflow: "hidden",
        },
        outlined: {
          "&.focused .notchedOutline": {
            borderColor: "transparent",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          // eslint-disable-next-line lingui/no-unlocalized-strings
          border: "1px solid #d0d0d0",
          borderRadius: 6,
          padding: 2,
          "& svg": {
            visibility: "hidden",
          },
          "&.Mui-checked": {
            "& svg": {
              // eslint-disable-next-line lingui/no-unlocalized-strings
              visibility: "visible !important",
            },
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 889,
      lg: 1280,
      xl: 1920,
    },
  },
});
