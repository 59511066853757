import { Link } from "@mui/material";

type SectionButtonProps = {
  title: string;
  href?: string;
  isMobile?: boolean;
};

export const SectionButton: React.FC<SectionButtonProps> = ({ title, isMobile, href }) => {
  const linkProps = {
    className: isMobile ? "link__mobile_title" : "link__title",
  };

  return (
    <li>
      <Link sx={{ textDecoration: "none" }} {...linkProps} href={href}>
        {title}
      </Link>
    </li>
  );
};
